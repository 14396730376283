import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + "/tags";

class TagService {

    getAll(params) {
        return axios.get(API_URL, {
            params: params,
            headers: authHeader()
        });
    }

    get(id) {
        return axios.get(API_URL + '/' + id, { headers: authHeader() });
    }

    saveOrUpdate(data) {
        if (!data.id) {
            return axios.post(API_URL,
                data,
                { headers: authHeader() });
        }
        else {
            return axios.put(API_URL + '/' + data.id,
                data,
                { headers: authHeader() });
        }
    }

    delete(id) {
        return axios.delete(API_URL + '/' + id, { headers: authHeader() });
    }
}

export default new TagService();
