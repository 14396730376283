 <template>
    <section class="tag-input">
      <vue-tags-input
        v-model="tag"
        :tags="tags"
        :autocomplete-items="filteredItems"
        @tags-changed="updateTags"
        placeholder="Adicionar Tags"
      />
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import TagService from "../services/tag.service";

export default {
  name: "TagInput",
  components: {
    VueTagsInput,
  },
  props: ['value'],
  data() {
    return {
      tag: "",
      tags: [],
      projetoTags: [],
    };
  },
  computed: {
    ...mapState(["projetoSelecionado"]),
    filteredItems() {
      return this.projetoTags.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
    },
  },
  mounted() {
    this.loadTags();
  },
  watch: {
    projetoSelecionado: function () {
      this.loadTags();
      this.tag = "";
      this.tags = [];
    },
  },
  methods: {
    ...mapActions(["atualizarProjetoSelecionadoAction"]),
    loadTags() {
      if (this.projetoSelecionado) {
        TagService.getAll({ projeto_id: this.projetoSelecionado.id }).then(
          (response) => {
            this.projetoTags = [];
            response.data.forEach(tag => {
              this.projetoTags.push({text: tag.nome});
            });
          }
        );
      }
    },
    updateTags(newTags) {
      this.tags = newTags;
      let plainTags = newTags.reduce((accumulator, item) => {
        accumulator.push(item.text);
        return accumulator;
      }, []);

      this.$emit('input', plainTags);
    }
  },
};
</script>
