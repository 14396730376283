 <!--Seletor de projetos do tipo Prospecção-->
 <template>
  <div>
    <label class="sr-only">Projeto</label>
    <multiselect
      :value="projetoSelecionado"
      @input="atualizarProjetoSelecionadoAction"
      :options="projetos"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="true"
      :preserve-search="true"
      placeholder="Selecionar projeto"
      label="nome"
      track-by="id"
      selectLabel="Enter para selecionar"
      selectedLabel="Selecionado"
      deselectLabel="Enter para remover"
    >
      <template slot="noOptions">Lista está vazia.</template>
    </multiselect>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ProjetoService from "../services/projeto.service";

export default {
  name: "ProjetoSelect",
  data() {
    return {
      projetos: [],
    };
  },
  computed: {
    ...mapState(["projetoSelecionado"]),
  },
  mounted() {
    ProjetoService.getAll({ projeto_tipo_id: 2 }).then((response) => {

      
      this.projetos = response.data;
    });
  },
  methods: {
    ...mapActions(["atualizarProjetoSelecionadoAction"]),
  },
};
</script>